.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
}

.main-footer {
  background-color: #f8f9fa;
  text-align: center;
}

.page-item{
  cursor: pointer;
}

/* Ventana Modal */
/* Fondo oscuro detrás del modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo translúcido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Encima de otros elementos */
}

/* Contenedor principal del modal */
.modal-content {
  background: #fefefe; /* Fondo blanco tradicional */
  border: 1px solid #ccc; /* Borde gris claro */
  border-radius: 5px; /* Esquinas ligeramente redondeadas */
  padding: 20px;
  width: 400px;
  max-width: 90%; /* Adaptable en pantallas pequeñas */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Ligera sombra */
  text-align: left; /* Alineación de texto tradicional */
  position: relative;
}

/* Botón de cierre */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: #444; /* Gris oscuro */
  cursor: pointer;
}

.close-button:hover {
  color: #000; /* Negro al pasar el cursor */
}

/* Títulos */
.modal-content h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

/* Párrafos */
.modal-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.4;
}

/* Botones dentro del modal */
.modal-content button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 15px;
}

.modal-content button:hover {
  background-color: #0056b3; /* Azul más oscuro */
}
/* FIN Ventana Modal */